import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { getGatsbyImageNode } from "../../../utils/getGatsbyImageNode";

const query = () => {
  const data = useStaticQuery(graphql`
    query MyQuery {
      company: allFile(filter: { name: { eq: "company.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              companyName
              slogan
              cidade
              subtitle
            }
          }
        }
      }
      Local: allImageSharp(
        filter: { original: { src: { regex: "/.*/localizacao.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      contato: allFile(filter: { name: { eq: "contato.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              telefones {
                whatsapp
                fixos
                agendamento
                plantao
              }
              horarios {
                segunda
                terca
                quarta
                quinta
                sexta
                sabado
                domingo
              }
              endereco {
                rua
                cidade
                estado
                CEP
                addressLink
                key
                placeId
              }
            }
          }
        }
      }
      redes: allFile(filter: { name: { eq: "redes-sociais.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              facebook
              instagram {
                user
                post
              }
              linkedin
              whatsapp
              mensagem
              empresa
            }
          }
        }
      }
      servicos: allFile(filter: { name: { eq: "servicos.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              servicos {
                descricao
                titulo
                foto
                svg
                url
              }
            }
          }
        }
      }
      analytics: allFile(filter: { name: { eq: "analytics.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              gtm
              ga
              pixelValue
            }
          }
        }
      }
      popup: allFile(filter: { name: { eq: "pop-up-24h.metadata" } }) {
        nodes {
          childMetadatasJson {
            titulo
          }
        }
      }
      popUpImage: allImageSharp(
        filter: { original: { src: { regex: "/.*pop-up-emergencia.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED, width: 300, height: 300)
          }
        }
      }
      cta: allFile(filter: { name: { eq: "cta.metadata" } }) {
        nodes {
          childMetadatasJson {
            title
            subtitle
          }
        }
      }
      landingImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-landing.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(width: 6000, height: 4000, placeholder: BLURRED)
          }
        }
      }
      logoLanding: allImageSharp(
        filter: { original: { src: { regex: "/.*/logo-landing.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              width: 700
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      aboutImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-about.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      ctaImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-cta.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      instagramImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/instagram-image.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              width: 500
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      logoTopbar: allImageSharp(
        filter: { original: { src: { regex: "/.*logo-topbar.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              width: 300
              height: 200
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      logoFooter: allImageSharp(
        filter: { original: { src: { regex: "/.*logo-footer.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              width: 300
              height: 200
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      serviceLandingImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/servicos-landing.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      logoMonoMainImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/logo-monomain.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              width: 250
              height: 250
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      servicePageMetadata: allFile(
        filter: { name: { eq: "servicos-page.metadata" } }
      ) {
        edges {
          node {
            childMetadatasJson {
              subtitle
              title
            }
          }
        }
      }
      galeriaLandingImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/galeria-landing.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      galeriaPageMetadata: allFile(
        filter: { name: { eq: "galeria-page.metadata" } }
      ) {
        edges {
          node {
            childMetadatasJson {
              subtitle
              title
            }
          }
        }
      }
      photos: allImageSharp(
        filter: { original: { src: { regex: "/.*gallery-img.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      contatoLandingImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/contato-landing.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      contatoPageMetadata: allFile(
        filter: { name: { eq: "contato-page.metadata" } }
      ) {
        edges {
          node {
            childMetadatasJson {
              subtitle
              title
            }
          }
        }
      }
      serviceLandingPhotos: allImageSharp(
        filter: { original: { src: { regex: "/.*descricao.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
            original {
              src
            }
          }
        }
      }
      products: allProdutosMetadataJson {
        edges {
          node {
            categorias {
              imgFilename
              nome
            }
            produtos {
              apresentation
              brand
              category
              flavor
              howToUse
              imgFilename
              name
            }
            subtitle
            title
          }
        }
      }
      productsItemImgs: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-petshop-item-*/" } } }
      ) {
        nodes {
          gatsbyImageData
          original {
            src
          }
        }
      }
      productsCategoriaImgs: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-categoria-*/" } } }
      ) {
        nodes {
          gatsbyImageData
          original {
            src
          }
        }
      }
      # googleAverage: googleAverageReview {
      #   name
      #   rating
      #   user_ratings_total
      # }
      # googleComments: allGoogleReview {
      #   nodes {
      #     author_name
      #     photo {
      #       childImageSharp {
      #         gatsbyImageData(placeholder: BLURRED)
      #       }
      #     }
      #     profile_photo_url
      #     rating
      #     relative_time_description
      #     text
      #   }
      # }
      covenantsImages: allImageSharp(
        filter: { original: { src: { regex: "/.*convenio-logo.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      aboutDestaque: allImageSharp(
        filter: { original: { src: { regex: "/.*img-servico-destaque.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(
              placeholder: BLURRED
              transformOptions: { fit: INSIDE }
            )
          }
        }
      }
      AlertImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-alert.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      respostasGpt: allFile(filter: { name: { eq: "data.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              responses {
                h2
                about1
                about2
                descriptionService
                whatsMessage
              }
            }
          }
        }
      }
      videos: allFile(filter: { name: { regex: "/.*landing-vid.*/" } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
      countdown: allFile(
        filter: { name: { eq: "countdown.container.metadata" } }
      ) {
        nodes {
          childMetadatasJson {
            eventDate
            eventName
            subtitle
          }
        }
      }
      image2: allImageSharp(
        filter: { original: { src: { regex: "/.*/landing-2.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      image3: allImageSharp(
        filter: { original: { src: { regex: "/.*/landing-3.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      image4: allImageSharp(
        filter: { original: { src: { regex: "/.*/landing-4.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      image5: allImageSharp(
        filter: { original: { src: { regex: "/.*/landing-5.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      imageAbout1: allImageSharp(
        filter: { original: { src: { regex: "/.*/imageAbout-1.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      imageAbout2: allImageSharp(
        filter: { original: { src: { regex: "/.*/imageAbout-2.*/" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      curriculo: allFile(
        filter: { name: { eq: "curriculo.container.metadata" } }
      ) {
        edges {
          node {
            childMetadatasJson {
              nome
              titulo
              qualificacoes {
                svg
                description
              }
              descricoes
              contatos
            }
          }
        }
      }
      equipe: allFile(filter: { name: { eq: "equipe.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              membros {
                name
                role
                description
                photoFilename
              }
            }
          }
        }
      }
      equipeFotos: allImageSharp(
        filter: { original: { src: { regex: "/.*/equipe-*/" } } }
      ) {
        nodes {
          gatsbyImageData
          original {
            src
          }
        }
      }
      pricesAndPlans: allFile(
        filter: { name: { eq: "prices-and-plans.metadata" } }
      ) {
        nodes {
          childMetadatasJson {
            plans {
              name
              price
              description
              icon
              benefitsChecks
              color
            }
            benefits
          }
        }
      }
      galleryVideos: allFile(filter: { name: { regex: "/.*gallery-vid.*/" } }) {
        edges {
          node {
            name
            publicURL
          }
        }
      }
      serviceLandingHeroImageTop: allImageSharp(
        filter: {
          original: { src: { regex: "/.*/service-landing-hero1.*/g" } }
        }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      serviceLandingHeroImageBottom: allImageSharp(
        filter: {
          original: { src: { regex: "/.*/service-landing-hero2.*/g" } }
        }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      contactParallaxImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-back-contato.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      servicesTopicsImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-services-topics.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      aboutParallaxImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/img-parallax.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      mapsImage: allImageSharp(
        filter: { original: { src: { regex: "/.*/maps.*/g" } } }
      ) {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
      partners: allFile(filter: { name: { eq: "partners.metadata" } }) {
        edges {
          node {
            childMetadatasJson {
              partners {
                logo
                name
                whats
                whatsMsg
                tel
              }
            }
          }
        }
      }
    }
  `);

  return data;
};

export const queryResult = () => {
  const data = query();

  const popUpImage = getImage(data.popUpImage.edges[0].node.gatsbyImageData)!;
  const landingImage = getImage(
    data.landingImage.edges[0].node.gatsbyImageData
  )!;
  const logoLanding = getImage(data.logoLanding.edges[0].node.gatsbyImageData)!;
  const aboutImage = getImage(data.aboutImage.edges[0].node.gatsbyImageData)!;
  const ctaImage = getImage(data.ctaImage.edges[0].node.gatsbyImageData)!;
  const instagramImage = getImage(
    data.instagramImage.edges[0].node.gatsbyImageData
  )!;
  const logoTopbar = getImage(data.logoTopbar.edges[0].node.gatsbyImageData)!;
  const logoFooter = getImage(data.logoFooter.edges[0].node.gatsbyImageData)!;
  const aboutTwoSideImage = getImage(
    data.aboutDestaque.edges[0].node.gatsbyImageData
  )!;
  let imageAbout1 = getImage(data.imageAbout1.edges[0].node.gatsbyImageData)!;
  let imageAbout2 = getImage(data.imageAbout2.edges[0].node.gatsbyImageData)!;
  // let imageAbout3 = getImage(data.imageAbout3.edges[0].node.gatsbyImageData)!;
  let image2 = getImage(data.image2.edges[0].node.gatsbyImageData)!;
  let image3 = getImage(data.image3.edges[0].node.gatsbyImageData)!;
  let image4 = getImage(data.image4.edges[0].node.gatsbyImageData)!;
  let image5 = getImage(data.image5.edges[0].node.gatsbyImageData)!;

  const serviceLandingImage = getImage(
    data.serviceLandingImage.edges[0].node.gatsbyImageData
  )!;
  const logoMonoMainImage = getImage(
    data.logoMonoMainImage.edges[0].node.gatsbyImageData
  )!;
  const Local = getImage(data.Local.edges[0].node.gatsbyImageData)!;
  const popUpTitle = data.popup.nodes[0].childMetadatasJson.titulo;
  const landingTitle =
    data.company.edges[0].node.childMetadatasJson.companyName;
  const landingSubtitle =
    data.company.edges[0].node.childMetadatasJson.subtitle;
  const whats = data.redes.edges[0].node.childMetadatasJson.whatsapp;
  const whatsMessage = data.redes.edges[0].node.childMetadatasJson.mensagem;
  const companyName = data.company.edges[0].node.childMetadatasJson.companyName;
  const slogan = data.company.edges[0].node.childMetadatasJson.slogan;
  const city = data.company.edges[0].node.childMetadatasJson.cidade;
  const servicos = data.servicos.edges[0].node.childMetadatasJson.servicos;
  const ctaTitle = data.cta.nodes[0].childMetadatasJson.title;
  const ctaSubtitle = data.cta.nodes[0].childMetadatasJson.subtitle;
  const address = data.contato.edges[0].node.childMetadatasJson.endereco;
  const agendamento =
    data.contato.edges[0].node.childMetadatasJson.telefones.agendamento;
  const fixos = data.contato.edges[0].node.childMetadatasJson.telefones.fixos;
  const plantao =
    data.contato.edges[0].node.childMetadatasJson.telefones.plantao;
  const whatsContact =
    data.contato.edges[0].node.childMetadatasJson.telefones.whatsapp;
  const horarios = data.contato.edges[0].node.childMetadatasJson.horarios;
  const facebook = data.redes.edges[0].node.childMetadatasJson.facebook;
  const instagram = data.redes.edges[0].node.childMetadatasJson.instagram;
  const linkedin = data.redes.edges[0].node.childMetadatasJson.linkedin;

  let videos = data.videos.edges;
  const eventName = data.countdown.nodes[0].childMetadatasJson.eventName;
  const eventDate = data.countdown.nodes[0].childMetadatasJson.eventDate;
  const subtitleCount = data.countdown.nodes[0].childMetadatasJson.subtitle;

  const videosUrls = videos.map((video: any) => video.node.publicURL);

  const curriculo = data.curriculo.edges[0].node.childMetadatasJson;

  const h2Text =
    data.respostasGpt?.edges[0].node.childMetadatasJson.responses.h2 ||
    companyName;
  const about1 =
    data.respostasGpt?.edges[0].node.childMetadatasJson.responses.about1 ||
    "Este aqui é um texto default para teste";
  const about2 =
    data.respostasGpt?.edges[0].node.childMetadatasJson.responses.about2 ||
    "Gerando um texto aleatório para o card do about, desde que esse texto preencha o tamanho necessário.";
  const descriptionService =
    data.respostasGpt?.edges[0].node.childMetadatasJson.responses
      .descriptionService || "Descrição dos serviços";
  const MensagemWhats =
    data.respostasGpt?.edges[0].node.childMetadatasJson.responses
      .whatsMessage ||
    "Olá, vim pelo site e gostaria de saber mais sobre os serviços.";

  const covenantsImages = data.covenantsImages.edges.map((covenant: any) => {
    return getGatsbyImageNode(
      getImage(covenant.node.gatsbyImageData)!,
      "Logo do Convênio",
      true
    );
  });

  // Pegando dados de metadata de equipe
  const equipe = data.equipe.edges[0].node.childMetadatasJson.membros;
  const equipeFotos = data.equipeFotos.nodes;
  equipe.forEach((member: any) => {
    const imgFilenameRegex = new RegExp(`.*${member.photoFilename}.*`);
    const photoImgNode = equipeFotos.find((photo: any) =>
      photo.original.src.match(imgFilenameRegex)
    );
    member["photo"] = getGatsbyImageNode(getImage(photoImgNode)!, member.name);
  });

  const plans = data.pricesAndPlans.nodes[0].childMetadatasJson.plans;
  const benefits = data.pricesAndPlans.nodes[0].childMetadatasJson.benefits;

  const servicePageTitle =
    data.servicePageMetadata.edges[0].node.childMetadatasJson.title;
  const servicePageSubtitle =
    data.servicePageMetadata.edges[0].node.childMetadatasJson.subtitle;

  // const googleCompanyName = data.googleAverage.name;
  // const googleAverageRating = data.googleAverage.rating;
  // const googleUserRatingTotal = data.googleAverage.user_ratings_total;
  // const googleComments = data.googleComments.nodes;
  // const googleCommentsObject = googleComments.map((comment: any) => {
  //   return {
  //     ...comment,
  //     photo: getGatsbyImageNode(
  //       comment.photo.childImageSharp.gatsbyImageData,
  //       "Foto do Usuário"
  //     ),
  //   };
  // });

  const galeriaLandingImage = getImage(
    data.galeriaLandingImage.edges[0].node.gatsbyImageData
  )!;
  const galeriaPageTitle =
    data.galeriaPageMetadata.edges[0].node.childMetadatasJson.title;
  const galeriaPageSubtitle =
    data.galeriaPageMetadata.edges[0].node.childMetadatasJson.subtitle;
  const galleryVideos = data.galleryVideos.edges.map(
    (video: any) => video.node.publicURL
  );

  const photos = data.photos.edges.map((photo: any) => {
    return getGatsbyImageNode(
      getImage(photo.node.gatsbyImageData)!,
      "Foto da Galeria"
    );
  });

  const contatoLandingImage = getImage(
    data.contatoLandingImage.edges[0].node.gatsbyImageData
  )!;
  const contatoPageTitle =
    data.contatoPageMetadata.edges[0].node.childMetadatasJson.title;
  const contatoPageSubtitle =
    data.contatoPageMetadata.edges[0].node.childMetadatasJson.subtitle;

  const landingServiceImage = data.serviceLandingPhotos.edges;

  const GA4 = data.analytics.edges[0].node.childMetadatasJson.ga;

  const serviceLandingHeroImageTop = getImage(
    data.serviceLandingHeroImageTop.edges[0].node.gatsbyImageData
  )!;
  const serviceLandingHeroImageBottom = getImage(
    data.serviceLandingHeroImageBottom.edges[0].node.gatsbyImageData
  )!;
  const contactParallaxImage = getImage(
    data.contactParallaxImage.edges[0].node.gatsbyImageData
  )!;
  const servicesTopicsImage = getImage(
    data.servicesTopicsImage.edges[0].node.gatsbyImageData
  )!;
  const aboutParallaxImage = getImage(
    data.aboutParallaxImage.edges[0].node.gatsbyImageData
  )!;

  const mapsImage = getImage(data.mapsImage.edges[0].node.gatsbyImageData)!;

  // Pegando dados de metadata de parceiros
  const partners = data.partners.edges[0].node.childMetadatasJson.partners;
  // const partnersFotos = data.partnersLogos.nodes;
  // partners.forEach((partner: any) => {
  //   if (partner.logo !== "undefined") {
  //     const imgFilenameRegex = new RegExp(`.*${partner.logo}.*`);
  //     const logoImgNode = partnersFotos.find((photo: any) =>
  //       photo.original.src.match(imgFilenameRegex)
  //     );
  //     partner["logo"] = getGatsbyImageNode(
  //       getImage(logoImgNode)!,
  //       partner.name,
  //       true,
  //       false,
  //       false
  //     );
  //   } else {
  //     partner["logo"] = undefined;
  //   }
  // });

  return {
    popUpImage,
    landingImage,
    logoLanding,
    Local,
    aboutImage,
    ctaImage,
    instagramImage,
    logoTopbar,
    logoFooter,
    aboutTwoSideImage,
    imageAbout1,
    imageAbout2,
    image2,
    image3,
    image4,
    image5,
    popUpTitle,
    landingTitle,
    landingSubtitle,
    whats,
    whatsMessage,
    companyName,
    slogan,
    city,
    servicos,
    ctaTitle,
    ctaSubtitle,
    address,
    agendamento,
    fixos,
    plantao,
    whatsContact,
    horarios,
    facebook,
    instagram,
    linkedin,
    videos,
    eventName,
    eventDate,
    subtitleCount,
    videosUrls,
    curriculo,
    h2Text,
    about1,
    about2,
    descriptionService,
    MensagemWhats,
    covenantsImages,
    equipe,
    equipeFotos,
    plans,
    benefits,
    //googleCompanyName,
    //googleAverageRating,
    //googleUserRatingTotal,
    //googleCommentsObject,
    serviceLandingImage,
    logoMonoMainImage,
    servicePageTitle,
    servicePageSubtitle,
    galeriaLandingImage,
    galeriaPageTitle,
    galeriaPageSubtitle,
    galleryVideos,
    photos,
    contatoLandingImage,
    contatoPageTitle,
    contatoPageSubtitle,
    landingServiceImage,
    GA4,
    serviceLandingHeroImageTop,
    serviceLandingHeroImageBottom,
    contactParallaxImage,
    servicesTopicsImage,
    aboutParallaxImage,
    mapsImage,
    partners,
  };
};
